<template>
  <div>
    <div class="header">
      <img :src="logo" alt="" class="logo" />
      <div class="lang d-flex flex-row">
        <!-- <div @click="changeLang('cn')">{{$t('map.chinese')}}</div>
        <div @click="changeLang('en')">English</div>-->
        <!-- <div>{{ $t("login.userName") }}</div> -->
        <el-select v-model="sel_lang" @change="changeLang">
          <el-option value="en" label="English"></el-option>
          <el-option value="cn" :label="$t('map.chinese')"></el-option>
          <!-- <el-option value="cn">
            <span>
              <a @click="changeLang('cn')">{{ $t("map.chinese") }}</a>
            </span>
          </el-option>
          <el-option value="en">
            <span>
              <a @click="changeLang('en')">English</a>
            </span>
          </el-option> -->
        </el-select>
      </div>
    </div>
    <div class="main">
      <el-carousel>
        <el-carousel-item>
          <img :src="car_img" alt="" />
        </el-carousel-item>
        <!-- <el-carousel-item>
          <img src="../assets/car2.png" alt="" />
        </el-carousel-item> -->
      </el-carousel>
      <div class="form">
        <el-form
          ref="ruleFormRef"
          :show-message="false"
          :model="ruleForm"
          :rules="rules"
          status-icon
        >
          <div class="box">
            <div class="it mb-4">
              <el-form-item prop="username">
                <el-input
                  v-model="ruleForm.username"
                  :placeholder="$t('login.userName')"
                  :prefix-icon="User"
                  size="large"
                />
              </el-form-item>
            </div>
            <div class="it mb-4">
              <el-form-item prop="userpwd">
                <el-input
                  type="password"
                  size="large"
                  v-model="ruleForm.userpwd"
                  :placeholder="$t('login.userPwd')"
                  :prefix-icon="Lock"
                />
              </el-form-item>
            </div>
            <div class="it">
              <el-button
                @click="submitForm(ruleFormRef)"
                size="large"
                class="w-100"
                type="success"
                >{{ $t("login.login") }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { User, Lock } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import GpsSocket from "../utils/GpsSocket";
export default {
  setup(props, content) {
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const { t, locale } = useI18n();

    const ruleFormRef = ref();
    const ruleForm = reactive({
      username: "",
      userpwd: "",
    });

    const rules = reactive({
      username: [
        {
          required: true,
          message: t("login.empty_username"),
          trigger: "blur",
        },
        // {
        //   min: 5,
        //   max: 16,
        //   message: t("login.length_username"),
        //   trigger: "blur",
        // },
      ],
      userpwd: [
        {
          required: true,
          message: t("login.empty_userpwd"),
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          // console.log("submit!");
          proxy.$api.gps_login(ruleForm).then((res) => {
            if (res.data.result == 1) {
              store.commit("setToken", res.data.token);
              store.commit("setuName", ruleForm.username.toLowerCase());
              store.commit("setPwd", res.data.pwd);
              store.commit("changeRouteStatus", false);

              store.commit("setOrderIp", res.data.SOCKET_IP);
              store.commit("setOrderPort", res.data.Video_Server_Port);
              store.commit("setVideoIp", res.data.Video_Server_Ip);
              store.commit("setVideoPort", res.data.Video_FLV_Port); //Video_Server_Port_Playback
              store.commit(
                "setVideoPlaybackPort",
                res.data.Video_Server_Port_Playback
              );
              GpsSocket.sendMsg("login", {
                username: "",
                password: "",
                session: res.data.token,
                SubscribeType: 2,
              });
              router.push({ path: "/map" });
            } else {
              if (typeof res.data.msgType === undefined) {
                res.data.msgType = 2;
              }
              ElMessage({
                showClose: true,
                message: t("login.msg_type_" + res.data.msgType),
                type: "warning",
              });
            }

            // console.log(res.data);
          });
          // store.commit("setToken", "token");
          // store.commit("setuName", "token");
          // store.commit("changeRouteStatus", false);
          // router.push({ path: "/" });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const logo = ref("");
    const car_img = ref("");
    proxy.$api.get_config_imgs({}).then((res) => {
      // console.log(res.data);
      logo.value = proxy.$imgUrl + res.data.logo;
      car_img.value = proxy.$imgUrl + res.data.login_url;
      // console.log(logo);
      document.title = res.data.website_name;
      let gps_lang = res.data.gps_lang;
      if (gps_lang == "zh-cn") {
        gps_lang = "cn";
      }
      locale.value = gps_lang;
      localStorage.setItem("lang", gps_lang);
      proxy.sel_lang = gps_lang;
    });

    return {
      User,
      Lock,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      logo,
      car_img,
    };
  },
  data() {
    return {
      sel_lang: "en",
    };
  },
  methods: {
    changeLang(lang) {
      // console.log(lang);
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      // this.sel_lang = lang;
      // if (lang == "cn") {
      //   this.values = "简体中文";
      // }
      //console.log('value是' +  lang)
    },
  },
};
//<script setup lang="ts">
// import { useI18n } from 'vue-i18n'
// const { locale } = useI18n()

// const changeLang = (lang: string) => {
//   locale.value = lang
//   localStorage.setItem('lang', lang)
// }
//< /script>
</script>

<style lang="scss" scoped>
.header {
  background-color: rgb(255, 255, 255);
  width: 100%;
  position: relative;
  height: 80px;
  .logo {
    position: absolute;
    left: 8%;
    top: 10px;
    width: 250px;
    height: 60px;
  }
  .lang {
    position: absolute;
    right: 8%;
    top: 30px;
    font-size: 14px;
    div {
      padding: 0 15px;
      border-right: 1px solid rgb(153, 153, 153);
      cursor: pointer;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.main {
  background-image: url(../assets/login3.jpg);
  position: absolute;
  width: 100%;
  top: 80px;
  bottom: 50px;
  background-repeat: no-repeat;
  background-color: rgb(59, 93, 139);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep(.el-carousel) {
    width: 55%;
    height: 70%;
    min-height: 350px;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 5px 0px;
    margin-right: 2%;
    // ::v-deep(.el-carousel) {
    //   height: inherit;
    // }
  }
  ::v-deep(.el-carousel__container) {
    position: relative;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .form {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
    position: relative;
    margin-left: 2%;
    width: 25%;
    min-width: 300px;
    height: 70%;
    min-height: 350px;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 5px 0px;
    z-index: 2;
    .box {
      margin-top: 30%;
    }
  }
}
::v-deep .lang .el-input__wrapper {
  box-shadow: none !important;
}
::v-deep .lang .el-select {
  width: 110px;
  outline: none;
  color: #000;
  font-size: 14px;
}
::v-deep .lang .select-trigger {
  width: 90px;
}
::v-deep .lang .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: none !important;
}
::v-deep .lang .el-select .el-input__inner {
  font-size: 14px;
}
</style>
